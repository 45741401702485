import smoothScroll from './smooth-scroll-connect';

// //Connect Testimonials Carousel
// if (document.getElementById('connect-testimonials-carousel')) {
//     document.addEventListener('DOMContentLoaded', function () {
//         import('@splidejs/splide').then((Splide) => {
//             new Splide.default('#connect-testimonials-carousel', {
//                 type: 'fade',
//                 autoplay: true,
//                 pagination: false,
//             }).mount();
//         });
//     });
// }

//Page Navigation
if (document.getElementById('page-navigation')) {
    smoothScroll('navbar', 'service-menu-link');
}


// contact sales form
(function () {
    const contactSalesForm = document.getElementById('contactSalesForm');
    if (contactSalesForm) {
        contactSalesForm.addEventListener('submit', function (e) {
            e.preventDefault();
            const name = this.elements['name'].value;
            const phone = this.elements['phone'].value;
            const email = this.elements['email'].value;
            const recaptcha = this.elements['recaptcha'].value;
            const formMessage = document.querySelector('.form-message');
            formMessage.style.display = 'none';
            formMessage.classList.remove('error');
            axios
                .post('/api/authland-connect', {
                    name,
                    phone,
                    email,
                    recaptcha,
                })
                .then((response) => {
                    // TODO Add a GA event
                    formMessage.innerText = formMessage.dataset.success;
                })
                .catch((err) => {
                    const { data } = err.response;
                    formMessage.innerText = data?.message ? data.message : formMessage.dataset.error;
                    formMessage.classList.add('error');
                })
                .finally(() => (formMessage.style.display = 'block'));
        });
    }
})();

//Scroll to contact form on connect landing page
(function () {
    document.querySelectorAll('a[href^="#contact"]').forEach((anchor) => {
        anchor.addEventListener('click', function (e) {
            e.preventDefault();

            const targetId = this.getAttribute('href').substring(1);
            const targetElement = document.getElementById(targetId);

            if (targetElement) {
                const navbarElement = document.getElementById('navbar');

                if (navbarElement) {
                    // Get the computed height of the navbar
                    const navbarHeight = navbarElement.offsetHeight;
                    // Calculate the offset from the top of the page to the target element
                    const targetPosition = targetElement.getBoundingClientRect().top + window.scrollY;
                    window.scrollTo({
                        top: targetPosition - navbarHeight,
                        behavior: 'smooth',
                    });
                }
            }
        });
    });
})();

/*Authland Connect Landing Page Fade In Effect*/
function handleIntersection(entries, observer) {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            entry.target.classList.add('visible');
            observer.unobserve(entry.target);
        }
    });
}

const observerOptions = {
    root: null,
    rootMargin: '0px',
    threshold: 0.5,
};

const intersectionObserver = new IntersectionObserver(handleIntersection, observerOptions);
const elements = document.querySelectorAll('.animate-on-scroll');

elements.forEach((el) => {
    intersectionObserver.observe(el);
});

//Pricing Switcher
// document.addEventListener("DOMContentLoaded", () => {
//     const buttons = document.querySelectorAll(".pricing-btn");
//     const cards = document.querySelectorAll(".pricing-card");

//     const pricingData = {
//         "6-months": {
//             1: "300€",
//             2: "420€"
//         },
//         "12-months": {
//             1: "500€",
//             2: "700€"
//         }
//     };

//     const updatePrices = (duration) => {
//         if (cards) {
//             cards.forEach((card) => {
//                 const cardId = card.getAttribute("data-card");
//                 const priceElement = card.querySelector(".card-price");
//                 priceElement.textContent = pricingData[duration][cardId];
//             });
//         }
//     };

//     if (buttons) {
//         buttons.forEach((button)=>{
//             button.addEventListener("click", () => {
//                 if (!button.classList.contains("active")) {
//                     buttons.forEach((btn) => {
//                         btn.classList.remove("active");
//                     });
//                     button.classList.add("active");
//                     updatePrices(button.dataset.duration);
//                 }
//             });
//         });
//     }

// });

// Pricing Carousel
if (document.getElementById('connect-pricing-carousel')) {
    document.addEventListener('DOMContentLoaded', function () {
        import('@splidejs/splide').then((Splide) => {
            new Splide.default("#connect-pricing-carousel", {
                type: 'slide',
                pagination: false,
                perPage: 4,
                perMove: 1,
                autoplay: 'false',
                arrowPath:
                    'M30.1708 18.6167C30.1795 18.6248 30.1881 18.633 30.1967 18.6411C31.2678 19.6677 31.2678 21.3323 30.1967 22.3589L14.6824 37.23C13.6114 38.2567 11.8748 38.2567 10.8038 37.23C9.73277 36.2034 9.73277 34.5389 10.8038 33.5123L24.3785 20.5003L10.8033 7.48767C9.73224 6.4611 9.73224 4.79676 10.8033 3.76993C11.8743 2.74336 13.6108 2.74336 14.6819 3.76993L30.1708 18.6167Z',
                classes: {
                    arrows: 'splide-custom-arrow',
                },
                breakpoints: {
                    1024: {
                        perPage: 2,
                    },
                    640: {
                        perPage: 1,
                    },
                },
            }).mount();
        });
    });
}
