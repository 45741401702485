function smoothScroll(navbarId, pageNavItemClass) {
    // Get the height of the page navigation bar (to account for offset when scrolling)
    let navbarHeight = document.getElementById(navbarId).offsetHeight;

    // Get all navigation items (links) inside the page navigation
    let pageNavItems = document.getElementsByClassName(pageNavItemClass);

    // Arrays to store section IDs and corresponding elements
    let itemsHref = [];
    let navSections = [];

    // Function to remove 'active' class from all navigation items
    function deselectAllHrefs() {
        document.querySelectorAll('.' + pageNavItemClass).forEach(el => el.classList.remove('active'));
    }

    // Loop through each navigation item and extract section IDs
    Array.from(pageNavItems).forEach(item => {
        // Get the section ID from the 'href' attribute (e.g., "#about" -> "about")
        let hrefValue = item.getAttribute('href').replace('#', '');

        // Find the corresponding section element by ID
        let section = document.getElementById(hrefValue);

        if (section) { // Ensure the section exists before adding to arrays
            itemsHref.push(hrefValue);
            navSections.push(section);
        }

        // Add click event listener to each navigation item
        item.addEventListener('click', function (event) {
            event.preventDefault(); // Prevent default anchor link behavior (jumping)

            // Calculate the target section's position relative to the top of the document
            let sectionPosition = section.getBoundingClientRect().top + window.pageYOffset - 80;

            // Smoothly scroll to the section
            window.scrollTo({ top: sectionPosition, behavior: 'smooth' });
        });
    });

    // Create an Intersection Observer to track which section is in view
    let observer = new IntersectionObserver((entries) => {
        // Find the first section that is currently in the viewport
        let firstVisible = entries.find(entry => entry.isIntersecting);

        if (firstVisible) {
            // Remove 'active' class from all navigation items
            deselectAllHrefs();

        // For both desktop and mobile, find the corresponding navigation link and add 'active' class
        const activeElements = document.querySelectorAll(`a[href="#${firstVisible.target.id}"]`);

        activeElements.forEach(activeElement => {
            // Ensure we are adding .active to both mobile and desktop links if they exist
            if (activeElement) {
                activeElement.classList.add('active');
            }
        });
        }
    }, {
        // Adjust the viewport margin to detect sections slightly before they fully appear
        rootMargin: `-${navbarHeight}px 0px -50% 0px`,
        threshold: 0.05 // The callback fires when at least 5% of the section is visible
    });

    // Attach observer to each section
    navSections.forEach(section => observer.observe(section));
}

export default smoothScroll;
